import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IContent } from '@models/content';
import { Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  constructor(private http: HttpClient) {}

  list: IContent[];
  getList(): Observable<IContent[]> {
    if (this.list) {
      return of(this.list);
    }
    return this.http
      .get<IContent[]>('content/tutorials')
      .pipe(tap((list) => (this.list = list)));
  }

  get(id: number): Observable<IContent> {
    if (this.list) {
      const item = this.list.find((i) => i.ContentId === id);
      console.log('get', item);
      if (item) {
        return of(item);
      }
    }
    return this.http.get<IContent>(`content/tutorials/${id}`);
  }
}
